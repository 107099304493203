<template>
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-2.18557e-07 5C2.57645 5 4.66424 2.76192 4.66424 2.0388e-07M-2.18557e-07 5L11 5M-2.18557e-07 5C2.57645 5 4.66424 7.23809 4.66424 10"
      stroke-miterlimit="10"
    />
  </svg>
</template>
