<template>
  <div
    v-if="items?.length"
    class="slider"
  >
    <Swiper
      ref="swiper"
      :modules="modules"
      :slides-per-view="1.15"
      :space-between="15"
      :navigation="{ nextEl: '.slider__nav__button--next', prevEl: '.slider__nav__button--prev' }"
      :pagination="true"
      :breakpoints="{
        768: {
          slidesPerView: slidesPerView?.md || 1.5,
          spaceBetween,
          pagination: true
        },
        992: {
          slidesPerView: slidesPerView?.lg || 2.5,
          spaceBetween,
          pagination: false,
        },
        1200: {
          slidesPerView: slidesPerView?.xl || 3,
          spaceBetween,
          pagination: false,
        },
      }"
      @swiper="onSwiper"
    >
      <template #container-start>
        <HSliderNav v-if="!hidePagination" />
      </template>

      <SwiperSlide
        v-for="(item, i) in items"
        :key="i"
        :class="swiperSlideClass"
      >
        <slot
          name="slide"
          :index="i"
          :item="item"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script lang="ts" setup>
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps<{
  swiperSlideClass?: string
  slidesPerView?: { md?: number, lg?: number, xl: number }
  items: Array<any>
  hidePagination?: boolean
}>()

const spaceBetween = 30

const onSwiper = (swiper) => {
  return swiper
}

const modules = [Navigation, Pagination]
</script>

<style lang="scss" scoped>
.slider {
  // margin-bottom: 32px;
}
</style>

<style lang="scss">
@import 'swiper/css';
@import 'swiper/css/pagination';

.swiper {
  overflow: visible;
}

.swiper-slide {
  height: auto;
}

.swiper-pagination {
  position: static;
  margin-top: 20px;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 28px;
  height: 5px;
  border-radius: 5px;
  background-color: $white;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: $purple;
}
</style>
