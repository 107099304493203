<template>
  <div class="slider__nav">
    <button
      type="button"
      class="slider__nav__button slider__nav__button--prev"
      :disabled="swiper?.isBeginning"
      aria-label="précédent"
      @click="swiper?.slidePrev()"
    >
      <SvgArrowLeft />
    </button>
    <button
      type="button"
      class="slider__nav__button slider__nav__button--next"
      :disabled="swiper?.isEnd"
      aria-label="suivant"
      @click="swiper?.slideNext()"
    >
      <SvgArrowRight />
    </button>
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()
</script>

<style lang="scss" scoped>
.slider {
  &__nav {
    margin-bottom: 25px;
    margin-left: auto;
    width: fit-content;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &__button {
      border: 1px solid #CECFD6;
      padding: 0.5em 1em;
      display: inline-flex;
      align-items: center;

      svg {
        stroke: $night-blue;
      }

      &:hover {
        border: 1px solid $purple;
        background: $purple;

        svg {
          stroke: $white;
        }
      }

      &--prev {
        margin-right: -1px;
        border-radius: 5px 0 0 5px;

        &:disabled {
          opacity: 0.5;
        }
      }

      &--next {
        border-radius: 0 5px 5px 0;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
